import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {toMoneyFormat} from "utils/formatFunctions";
import {
  Typography,
  Grid,
  IconButton,
  TextField,
  Paper,
} from "@material-ui/core";
import DialogDefault from "views/Widgets/OrdeerDashboard/DialogDefault";
import Indicator from "views/Widgets/OrdeerDashboard/Indicator";
import NewModalAdd from "views/Widgets/OrdeerDashboard/NewModalAdd";
import Button from "@material-ui/core/Button";
import { green, red, indigo, orange, yellow, blue } from "@material-ui/core/colors";
import { ButtonTextMUI as ButtonCustom } from "utils/componentsStyle";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CreateIcon from '@material-ui/icons/Create';
import MessageIcon from '@material-ui/icons/Message';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckIcon from '@material-ui/icons/Check';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StorageIcon from '@material-ui/icons/Storage';
import DetailNumber from "views/Widgets/FichaPaciente/detailNumber2";
import { getListSuppliesMedicines, } from "actions/getListAction";
import api from "utils/API";
import moment from "moment-timezone";
import DetailSelect from "views/Widgets/FichaPaciente/detailSelect";
import "moment/locale/es";
import {
  CustomTab,
  CustomTabs,
} from "utils/componentsStyle"
import {
  MedIcon,
  SupIcon,
  EquipIcon,
  EmergencyIcon,
} from "utils/iconsStyle"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import TableComponent from "views/Widgets/Common/TableComponent";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import { setmessageGeneral } from "actions/getGlobalAction";
import { getListOrderPatient } from "actions/getListAction";
import allActions from "actions";


moment.locale("es");
// eslint-disable-next-line react/prop-types
export default function RepearOrders({
  repearOrdersDialog,
  setRepearOrdersDialog,
  listaPrestaciones,
  patient,
  getPatientInfo,
  ordersPatientId,
  dateNextOrder=moment(),
}) {
  const dispatch = useDispatch();
  const [updateTable, setUpdateTable] = useState(false);
  const [repearOrder, setRepearOrder] = useState([]);
  const [dictRepearOrder, setdictRepearOrder] = useState({});
  const [modalMedical, setModalMedical] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [tempListaPrestaciones, setTempListaPrestaciones] = useState([...listaPrestaciones])
  const [dateAdmin, setdateAdmin] = useState(moment());
  const [openModalAdminOrder, setopenModalAdminOrder] = useState(true);
  const [openModalAdminOrderWarning, setopenModalAdminOrderWarning] = useState(null);
  const [openModalAdminOrderInfo, setopenModalAdminOrderInfo] = useState(null);
  const [openModalAdminAllItem, setopenModalAdminAllItem] = useState(null);
  const [openModalAdminItem, setopenModalAdminItem] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [deleteOrderList, setDeleteOrderList] = useState([]);
  const [dateSend, setdateSend] = useState(moment());
  const [datePay, setdatePay] = useState(moment());
  const [quantitySend, setquantitySend] = useState(1);
  const [selectOrderItem, setselectOrderItem] = useState(null);

  const [deleteArray, setDeleteArray] = useState([]);
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState([]);
  const [title, setTitle] = useState([]);
  const [tag, setTag] = useState(0);
  const [dataTag, setdataTag] = useState([]);
  const [flagCalculate, setFlagCalculate] = useState(false);
  const [totalValueNew, settotalValueNew] = useState(null);
  const [valuePackage, setvaluePackage] = useState(null);
  const [editableIndex, seteditableIndex] = useState(null);
  const [editableType, seteditableType] = useState(null);
  const [flagAlert, setFlagAlert] = useState(0);
  const [flagAlertSending, setFlagAlertSending] = useState(0);

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listSupplies = useSelector(state => state.currentList.listSupplies);
  const listOrdersPatient = useSelector(state => state.currentList.listOrdersPatient);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const dictDiscountQuotation = useSelector(state => state.currentList.dictDiscountQuotation);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);
  const quotationDiscount = useSelector(state => state.currentGlobalVar.quotationDiscount);
  const typeQuotationDiscount = useSelector(state => state.currentGlobalVar.typeQuotationDiscount);
  const [comment, setComment] = useState("");
  const limitObservation = 500

  const tempType = "_insumos_medicamentos";

  useEffect(() => {
    if (listMedical.length === 0 && !chargeMedicine) {
      getListSuppliesMedicines(dispatch);
    };
    if (tag == 0) {
      setdataTag(listMedical);
    } else {
      setdataTag(listSupplies);
    }
    if (flagCalculate) {
      calculate();
    }
  });

  useEffect(() => {
    if (orderList.length > 0 && selectOrderItem !== null) {
      setselectOrderItem(ordersData[0])
    }
  }, [orderList])

  useEffect(() => {setdictRepearOrder(repearOrder.reduce((acc, item) => ({ ...acc, [item.id]: {...item}}), {}))}, [repearOrder])

  useEffect(() => {setFlagCalculate(true)}, [dictRepearOrder, orderList])

  useEffect(() => {
    if (dateNextOrder){
      handleChangeDate(moment(dateNextOrder));
    }
    const tempData = tempListaPrestaciones.filter(item => (item.activo)).map((item, index) => {
      return {
        ...item,
        editableQuantity: false,
        envio_mensual: item.envio_mensual,
        idExternal: item.id,
        editableQuantitySending: false,
        id_paquete_paciente: item.id_paquete_paciente_id,
        id_prestaciones: item.id_prestaciones_id,
        id_insumo_medicamento: item.id_insumo_medicamento_id,
        index,
      }
    });
    setRepearOrder(tempData);
    setOrderList(listOrdersPatient)
  }, []);

  const calculate = () => {
    setFlagCalculate(false)
    let temptotalValueNew = 0
    let tempvaluePackage = 0

    repearOrder.filter(item => !item.fuera_paquete).map(item => {
      const tempDiscount = dictDiscountQuotation[`${item.id_insumo_medicamento}_insumos_medicamentos_false`]
      if(tempDiscount){
        tempvaluePackage += calculateIndividualDiscount(
          parseInt(item.cantidad || 0),
          item.valor,
          tempDiscount.porcentaje_item,
          tempDiscount.tipo_porcentaje
        )
      } else {

        tempvaluePackage += parseInt(item.cantidad || 0) * parseInt(item.id_insumo_medicamento__valor_con_iva)
      }
    })
    const dateAdminFormat = moment(dateAdmin).format("YYYY-MM")
    orderList.filter((row) => moment(row.fecha_cobro).format("YYYY-MM") === dateAdminFormat).map(row => {
      Object.values(row.items).filter((item) => dictRepearOrder[item.id_prestacion_paciente_id]?.fuera_paquete !== true && item.borrar !== true).map((item) => {
        const value = medicalResourceData[item.id_insumo_medicamento_id]?.valor_con_iva || 0
        const tempDiscount = dictDiscountQuotation[`${item.id_insumo_medicamento_id}_insumos_medicamentos_false`]
        if(tempDiscount){
          temptotalValueNew += calculateIndividualDiscount(
            parseInt(item.cantidad_envio),
            value,
            tempDiscount.porcentaje_item,
            tempDiscount.tipo_porcentaje
          )
        } else {
          temptotalValueNew += parseInt(item.cantidad_envio) * parseInt(value)
        }
      });
    });
    settotalValueNew(temptotalValueNew);
    setvaluePackage(tempvaluePackage);
  }

  const handleTag = (n) => {
    setTag(n);
    seteditableIndex(null);
  }

  const headerExtra = [
    { label: 'Item', colspan: '2', stylerow: { border: "10px solid white", background: "rgba(63, 81, 181, 0.2)" } },
    { label: 'Paquete General', colspan: '4', stylerow: { border: "10px solid white", background: "rgba(63, 81, 181, 0.2)" } },
    { label: 'Envíos', colspan: '4', stylerow: { border: "10px solid white", background: "rgba(63, 81, 181, 0.2)" } },
  ]

  const header = [
    { eval: true, label: 'Item ' },
    { eval: true, label: 'SKU' },
    { onlySort: true, label: 'Cantidad mensual ', style:{ width: "200px" }},
    { onlySort: true, label: 'Total' },
    { onlySort: true, label: 'Total con descuentos', style:{ width: "140px"}},
    { eval: false, label: 'Editar' },
    { onlySort: true, label: 'Cantidad a enviar', style:{ width: "200px" }},
    { onlySort: true, label: 'Total' },
    { onlySort: true, label: 'Total con descuentos', style:{ width: "140px"}},
    { eval: false, label: 'Editar' },
  ]

  const handleChangeDate = (value) => {
    if (moment(value).format("YYYY-MM-DD") !== moment(dateAdmin).format("YYYY-MM-DD")){
      setdateAdmin(moment(value));

      api.get(`/api/paciente/pedidosprogramados/prestacionespaquete/total/paciente/${patient.id_paciente_id}/?fecha_pedido=${moment(value).format("YYYY-MM-DD")}`).then(response => {
        const total_value = response.data.valor_solicitudes
        const total_package = response.data.valor_solicitudes
        dispatch({ payload: total_value, type: 'VALOR_CONSUMIDO_MES' })
        dispatch({ payload: total_package, type: 'VALOR_CONSUMIDO_PAQUETE' })
        const message = "Los valores del paciente han sido actualizados al periodo seleccionado"
        setmessageGeneral(dispatch, message, "success", "Actualización periodo completada");

        setFlagCalculate(true);
      });
    }
  }

  const saveData = () => {

    const tempOrder = orderList.reduce((acc, obj) => {
      const tempItem = {...obj};
      delete tempItem["editableDatePay"];
      delete tempItem["editableDateSend"];
      delete tempItem["edit"];

      tempItem["estado"] = "SinEnviar";

      tempItem.items = Object.values(tempItem.items).filter((row) => !deleteArray.includes(row.id_prestaciones_id) && (row.id || !row.id && row.cantidad_envio > 0 )).map((row) => {
        const temprow = {...row};
        delete temprow["quantitySend"];
        delete temprow["edit"];
        if (temprow.cantidad_envio === 0 && temprow.id){
          temprow.borrar = true;
        }
        return temprow;
      })
      tempItem["fecha_cobro"] =  moment(tempItem["fecha_cobro"]).format("YYYY-MM-DD")
      tempItem["fecha_envio"] = moment(tempItem["fecha_envio"]).format("YYYY-MM-DD")

      return [
        ...acc,
        tempItem,
      ]
    }, [])
    const body = {
      pedidos: tempOrder,
      pedidos_eliminados: deleteOrderList,
    }
    const requestQuotation = api.put(`api/paciente/prestacionespaciente/bulk/`, repearOrder);
    const requestDeleteQuotation = api.delete("api/paciente/prestacionespaciente/bulk/", { data: deleteArray });
    const requestOrderQuotation = api.post(`api/paciente/pedidoprogramado/bulk/?paciente=${patient.id_paciente_id}`, body);
    Promise.all([requestQuotation, requestDeleteQuotation, requestOrderQuotation]).then((request) => {
      setRepearOrdersDialog(false);
      const message = "Se ha guardado el pedido programado"
      setmessageGeneral(dispatch, message, "success", "Pedido guardado");
      getPatientInfo(patient.id_paciente_id);
    });

  }

  const handleClose = () => {
    getPatientInfo(patient.id_paciente_id);
    const tempData = listaPrestaciones.filter(item => (item.activo)).map((item, index) => {
      return {
        ...item,
        editableQuantity: false,
        envio_mensual: true,
        idExternal: item.id,
        editableQuantitySending: false,
        id_paquete_paciente: item.id_paquete_paciente_id,
        id_prestaciones: item.id_prestaciones_id,
        id_insumo_medicamento: item.id_insumo_medicamento_id,
        index,
      }
    });
    setTempListaPrestaciones([...listaPrestaciones]);
    setRepearOrder(tempData);
    setRepearOrdersDialog(false);
  }

  const modifyQuantity = (value, index) => {
    if (value){
      const auxDataArray = repearOrder;
      const tempRow = auxDataArray[index]
      tempRow.cantidad = value
      auxDataArray[index] = tempRow
      setUpdateTable(!updateTable)
      setRepearOrder(auxDataArray)
      setFlagCalculate(true);
    }
  }

  const deletePackage = (id) => {
    const arrayDeleteTemp = deleteArray
    arrayDeleteTemp.push(id)
    setDeleteArray(arrayDeleteTemp)
    setRepearOrder(repearOrder.filter(item => item.id != id).map((item, index) => {
      return {
        ...item,
        index,
      }
    }))
    setOrderList(orderList.map((row) => {
      const tempRow = {...row}
      if (tempRow.items[id]){
        delete tempRow.items[id]
      }
      return {...tempRow}
    }));
    setUpdateTable(!updateTable)
    setFlagCalculate(true);
    setModal(false)
  }

  const enableEdit = (enable, index) => {
    if (editableIndex === null || (editableIndex === index && editableType === "mensual") ){
      const auxDataArray = repearOrder;
      const tempRow = auxDataArray[index]
      tempRow.editableQuantity = enable
      auxDataArray[index] = tempRow
      setUpdateTable(enable)
      setRepearOrder(auxDataArray)
      if (editableIndex === null && editableType === null){
        seteditableIndex(index);
        seteditableType("mensual")
      } else {
        seteditableIndex(null);
        seteditableType(null);
        setFlagCalculate(true);
      }
    }
  }

  const handleAddItem = (value, quantity, SOS=0) => {
    const auxArray = repearOrder;
    const tempData = medicalResourceData[value.value];
    const body = {
      frecuencia: 1,
      tipo_frecuencia: "Meses",
      fuera_paquete: false,
      visibility: "visible",
      valor: parseInt(tempData?.valor_con_iva),
      id_insumo_medicamento_id: tempData.id,
      id_insumo_medicamento: tempData.id,
      id_prestaciones: null,
      id_equipo: null,
      id_paquete_paciente: ordersPatientId,
      cantidad: quantity,
      es_SOS: SOS,
      fuera_paquete: titleModal !== "AL PAQUETE"
    }


    api.post(`api/prestacionespaciente/`, body).then(response => {
      const item = {
        ...response.data,
        id_insumo_medicamento__SKU: tempData.SKU,
        id_insumo_medicamento__descripcion_producto: tempData.descripcion_producto,
        id_insumo_medicamento__grupo_producto: tempData.grupo_producto,
        id_insumo_medicamento__valor_con_iva: tempData.valor_con_iva,
        id_insumo_medicamento_id: tempData.id,
        idExternal: response.data.id,
        id_paquete_paciente_id: ordersPatientId,
        editableQuantitySending: false,
        editableQuantity: false,
      }
      auxArray.push(item);
      setRepearOrder(auxArray.map((item, index) => {
        return {
          ...item,
          index,
        }
      }));
      setUpdateTable(true);

    })
  };

  const checkDiscountItem = (row) => {
    return dictDiscountQuotation[`${row.id_insumo_medicamento}${tempType}_${row.fuera_paquete}`] || false;
  };

  const calculateIndividualDiscount = (amount, value, discount, typeDiscount) => {
    if (!value){
      return 0;
    }
    const total = amount*value;
    const totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
    return parseInt(totalDiscount == 0 ? total : total + (total*(totalDiscount/100)));
  };

  const calculateGeneralDiscount = (amount, value, discount, typeDiscount) => {
    let totalDiscount = 0;
    const total = amount*value;
    if (!value){
      return 0;
    } else if(discount && quotationDiscount){
      const tempDiscount1 =  typeDiscount === "Descuento" ? discount*-1 : discount;
      const tempDiscount2 =  typeQuotationDiscount === "Descuento" ? quotationDiscount*-1 : quotationDiscount;
      totalDiscount = total + (total*(tempDiscount1/100))
      return totalDiscount + (totalDiscount*(tempDiscount2/100));
    } else if(discount){
      totalDiscount = typeDiscount === "Descuento" ? discount*-1 : discount;
      return total + (total*(totalDiscount/100));
    } else if(quotationDiscount){
      totalDiscount = typeQuotationDiscount === "Descuento" ? quotationDiscount*-1 : quotationDiscount;
      return total + (total*(totalDiscount/100))
    } else {
      return amount*value;
    }
  };

  const OpenModalPackage = (id, row) => {

    let title = 'ELIMINAR DEL PAQUETE';
    let textContent = '¿Estás seguro que deseas eliminar este producto del paquete? Esto significa que no se enviará más al paciente.';

    let context =
      <Grid item container xs={12}>
        <Grid item container xs={12} style={{ paddingTop: "30px" }}>
          {textContent}
        </Grid>
        <Grid item container xs={12} style={{ paddingTop: "30px" }}>
          <Grid item container xs={6}>
            <ButtonCustom aria-label="close" variant={"outlined"} style={{ color: red[900], width: "125px", heigth: "36px" }} onClick={() => setModal(false)}>Cancelar </ButtonCustom>
          </Grid>
          <Grid item container xs={6} justify="flex-end">
            <ButtonCustom aria-label="close" variant={"outlined"} style={{ color: green[500], width: "125px", heigth: "36px" }} onClick={() => deletePackage(id)}>{'SÍ, ELIMINAR'} </ButtonCustom>
          </Grid>
        </Grid>
      </Grid>
    setModal(true)
    setTitle(title)
    setContent(context)
  }

  const RepearOrderDataPaquet = (flag) => {

    const arrayFilter = tag === 0 ? ["Medicamentos"] :
      tag === 1 ? ["Insumos", "Articulos", "Alimentos"] :
      ["Otros"]

    return repearOrder.map((row, index) => {
      const checkNote = row.nota_prestacion !== "" && row.nota_prestacion !== null;
      let _quantity_ =  row.cantidad > 0 ? <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center">
        <Grid item container xs={8} direction="row" justify="flex-end" alignItems="center">
          {row.editableQuantity ?
            <DetailNumber value={row.cantidad} id="outlined-number" type="number" min={1} InputLabelProps={{ shrink: true, }} onchange={(e) => modifyQuantity(e.target.value, row.index)} />
            : <Typography justify="center">{row.cantidad}</Typography>}
        </Grid>
        <Grid item container xs={4}>
          <IconButton variant={"outlined"} style={{ color: indigo[500] }} onClick={() => enableEdit(!row.editableQuantity, row.index)} >
            {
              ((editableIndex !== row.index && editableIndex !== null ) || editableType === "mensual" )  ?
              <CreateIcon variant={"outlined"} style={{ color: indigo[50] }} /> :
              row.editableQuantity ?
              <CheckCircleOutlineIcon variant={"outlined"} style={{ color: flagAlert < 0 ? "rgba(0, 0, 0, 0.26)" : green[500] }} /> :
              <CreateIcon variant={"outlined"} style={{ color: indigo[500] }} />
            }
          </IconButton>
        </Grid>
        {(editableIndex === row.index && editableIndex !== null && editableType === "mensual" ) &&
        <Grid item container xs={12}>
          {flagAlert < 0 && <Typography justify="center" variant="caption" style={{color: "#E31B0C"}}>
            La cantidad excede el monto disponible, disminúyela para no sobrepasarlo
          </Typography>
          }
        </Grid>
        }
      </Grid> : <div></div>

      const dateAdminFormat = moment(dateAdmin).format("YYYY-MM");
      let quantitySending = orderList.filter((order) => order.items[row.id] && order.items[row.id]?.borrar !== true && moment(order.fecha_cobro).format("YYYY-MM") === dateAdminFormat).reduce(
        (acc, obj) => acc + (parseInt(obj.items[row.id]?.cantidad_envio) || 0
        ) , 0
      )

      const discount = toMoneyFormat(calculateGeneralDiscount(
        row.cantidad,
        row.id_insumo_medicamento__valor_con_iva,
        checkDiscountItem(row).porcentaje_item,
        checkDiscountItem(row).tipo_porcentaje
      ))

      const total = toMoneyFormat(row.id_insumo_medicamento__valor_con_iva * quantitySending)
      const totalDiscount = toMoneyFormat(calculateGeneralDiscount(
        quantitySending,
        row.id_insumo_medicamento__valor_con_iva,
        checkDiscountItem(row).porcentaje_item,
        checkDiscountItem(row).tipo_porcentaje
      ))

      const _buttonOrder_ =  <ButtonCustom variant={"outlined"} style={{ color: red[900] }} onClick={() => OpenModalPackage(row.idExternal, !row.envio_mensual)}>
        <RemoveCircleIcon /> Eliminar del paquete 
      </ButtonCustom>
      const _buttonNextOrder_ = <ButtonCustom variant={"outlined"} style={{ color: green[500] }} onClick={() => {
        setopenModalAdminItem(row)
        setComment(row.nota_prestacion)
      }}>
        <AssignmentIcon /> Gestionar Item {checkNote &&  <MessageIcon />}
      </ButtonCustom>
      return {
        data: [
        {value: row.id_insumo_medicamento__descripcion_producto},
        {value: row.id_insumo_medicamento__SKU},
        {_value_: _quantity_, value: row.cantidad },
        {value: row.cantidad > 0 ? toMoneyFormat(row.id_insumo_medicamento__valor_con_iva * row.cantidad) : ''},
        {value: discount},
        {_value_: _buttonOrder_},
        {value: quantitySending},
        {value: total},
        {value: totalDiscount},
        {_value_: _buttonNextOrder_},
      ],
      show: (row.fuera_paquete === flag && arrayFilter.includes(row.id_insumo_medicamento__grupo_producto)),
    }
    })
  }

  console.log(valuePackage, totalValueNew)

  const _repearOrderContext_ = <Grid item container xs={12}>
    <Grid item container xs={12} alignContent="center" justify="left" style={{ marginTop: "3em" }}>
      <Grid item container md={3} lg={2}  xl={2}>
        <Button variant="contained" color="primary" style={{ width: "224px", height: "42px" }} onClick={() => setopenModalAdminOrder(true)}>
          GESTIONAR PEDIDOS
        </Button>
      </Grid>
      <Grid item container md={3} lg={2}  xl={2}>
          <Typography variant={'h6'} style={{ paddingTop: '15px' }}>
            Fecha de Gestión
          </Typography>
      </Grid>
      <Grid item container md={3} lg={2}  xl={2}>
        <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            margin="normal"
            id="date-picker-inline1-1"
            inputValue={moment(dateAdmin).format("MMMM YYYY")}
            openTo="year"
            views={["year", "month"]}
            onChange={(e) => handleChangeDate(e)}
            KeyboardButtonProps={{ "aria-label": "change date" }}
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
    {valuePackage ?
    <Indicator valuePackage={valuePackage} totalValueNew={totalValueNew} setFlagAlert={setFlagAlert} setFlagAlertSending={setFlagAlertSending}/> :
    <Indicator setFlagAlert={setFlagAlert} setFlagAlertSending={setFlagAlertSending}/>
    }
    <Grid item container xs={12} justify="center" alignContent="center" style={{margin: "0 0 31px 0"}} >
      <CustomTabs
        value={tag}
        onChange={(event, newValue) => handleTag(newValue)}
        variant="fullWidth"
        aria-label="tab_"
      >
        <CustomTab icon={<MedIcon fontSize="large"/>} value={0} label="MEDICAMENTOS" />
        <CustomTab icon={<SupIcon fontSize="large"/>} value={1} label="INSUMOS" />
        <CustomTab icon={<EquipIcon fontSize="large"/>} value={2} label="EQUIPOS" />
        <CustomTab icon={<EmergencyIcon fontSize="large"/>} value={3} label="EMERGENCIA"/>
      </CustomTabs>
    </Grid>
    <Grid item container xs={12} style={{ background: "#2196F3" }}>
      <Typography variant={"h6"} style={{ color: "#FFFFFF" }}>En paquete: Centro AltaVMI </Typography>
    </Grid>
    <Grid item container xs={12} style={{ marginTop: "25px" }}>
      <Grid item container xs={3}>
        <Button variant="contained" color="primary" style={{ width: "225px", height: "42px", background: green[500], color: '#FFFFFF' }} onClick={() => {
          setModalMedical(true);
          setTitleModal("AL PAQUETE");
        }}>
          AGREGAR AL PAQUETE
        </Button>
      </Grid>
    </Grid>
    <Grid item container xs={12} style={{ marginTop: "25px" }}>
      <TableComponent
        headerExtra={headerExtra}
        headers={header}
        data={RepearOrderDataPaquet(false)}
        pageSize={100}
      />
    </Grid>
    <Grid item container xs={12} style={{ background: "#2196F3" }}>
      <Typography variant={"h6"} style={{ color: "#FFFFFF" }}>Extras al paquete</Typography>
    </Grid>
    <Grid item container xs={12} style={{ marginTop: "25px" }}>
      <Grid item container xs={3}>
        <Button variant="contained" color="primary" style={{ width: "225px", height: "42px", background: green[500], color: '#FFFFFF' }} onClick={() => {
          setModalMedical(true);
          setTitleModal("AL PAQUETE EXTRA");
        }}>
          AGREGAR EXTRA
        </Button>
      </Grid>
    </Grid>
    <Grid item container xs={12} style={{ marginTop: "25px" }}>
      <TableComponent
          headerExtra={headerExtra}
          headers={header}
          data={RepearOrderDataPaquet(true)}
          pageSize={100}
        />
    </Grid>
    <DialogDefault title={title} titleStyle={{ textAlign: "center" }} content={content} openParameter={modal} setParamenter={setModal} />

  </Grid >

  const _repearOrderAction_ = <>
    <Button style={{ width: '224px', height: '42px', background: red[500], color: '#FFFFFF' }} onClick={handleClose}>
      CERRAR SIN GUARDAR
    </Button>
    {(flagAlertSending > 0 && flagAlert > 0) ?
    <Button variant="contained" color="primary" style={{ width: "224px", height: "42px", background: green[500], color: '#FFFFFF' }} onClick={saveData}>
      GUARDAR Y SALIR
    </Button> :
    <Button disabled variant="contained" color="primary" style={{ width: "224px", height: "42px", background: '#dddddd', color: '#FFFFFF' }}>
      GUARDAR Y SALIR
    </Button>
    }
  </>

  //----------------------------------ORDER----------------------------------------------

  const orderHeader = [
    { eval: true, label: 'nº pedido', style:{width: "105px"}},
    { eval: true, label: 'Fecha envío' },
    { eval: true, label: 'Periodo de cobro' },
    { eval: true, label: 'Total'},
    { eval: false, label: 'Acciones', style:{width: "200px"}},
  ]

  const handleCreateOrder = () => {
    const tempOrderList = [...orderList];
    const length = tempOrderList.length + 1;
    tempOrderList.push({
      numero_pedido: length,
      fecha_envio: dateSend,
      editableDateSend: dateSend,
      fecha_cobro: datePay,
      editableDatePay: datePay,
      id_paquete_paciente_id:  ordersPatientId,
      id_paquete_paciente:  ordersPatientId,
      estado: "SinEnviar",
      items: {},
    });
    setdatePay(moment());
    setdateSend(moment());
    setOrderList(tempOrderList);
  }

  const handleDeleteOrder = (valueIndex) => {
    const tempData = [...orderList];
    setopenModalAdminOrderWarning(null);
    setOrderList(tempData.filter((row,index) => index !== valueIndex));
    setDeleteOrderList([...deleteOrderList, tempData[valueIndex]?.id].filter((row) => row !== undefined));
    setFlagCalculate(true);
  }

  const handleEditOrder = (valueIndex) => {
    const tempData = [...orderList];
    tempData[valueIndex].edit = !tempData[valueIndex]?.edit;
    if (!tempData[valueIndex].edit) {
      tempData[valueIndex].fecha_envio = tempData[valueIndex].editableDateSend;
      tempData[valueIndex].fecha_cobro = tempData[valueIndex].editableDatePay;
    }
    setOrderList(tempData);
    setFlagCalculate(true);
  }

  const handleCancelEditOrder = (valueIndex) => {
    const tempData = [...orderList];
    tempData[valueIndex].edit = false
    tempData[valueIndex].editableDateSend = tempData[valueIndex].fecha_envio;
    tempData[valueIndex].editableDatePay = tempData[valueIndex].fecha_cobro;
    setOrderList(tempData);
  }

  const handleUpdateOrder = (value, type, valueIndex) => {
    const tempData = [...orderList];
    tempData[valueIndex][type] = moment(value);
    setOrderList(tempData);
    setFlagCalculate(true);
  }


  const ordersData = orderList.map((row, index) => {
    const _editButton_ =<IconButton variant={"outlined"} style={{ background: row.edit ? indigo[500] : orange[500], padding: "5px"}} onClick={() => handleEditOrder(index)} >
      {row.edit ? <CheckIcon style={{color: '#FFFFFF'}}  /> : <CreateIcon style={{color: '#FFFFFF'}} />}
    </IconButton>
    const _cancelEditButton_ = row.edit ? <IconButton variant={"outlined"} style={{ background: indigo[500], padding: "5px"}} onClick={() => handleCancelEditOrder(index)} >
      <ClearIcon style={{color: '#FFFFFF'}} />
    </IconButton> : null
    const _deleteButton_ = <IconButton variant={"outlined"} style={{ background: red[500], padding: "5px" }} onClick={() => setopenModalAdminOrderWarning(index)} >
      <DeleteForeverIcon style={{color: '#FFFFFF'}} />
    </IconButton>
    const _showInfo_ = <IconButton variant={"outlined"} style={{ background: blue[500], padding: "5px" }} onClick={() => setopenModalAdminOrderInfo(index)} >
      <StorageIcon style={{color: '#FFFFFF'}} />
    </IconButton>
    const _buttonAssingAllItem_ = <IconButton variant={"outlined"} style={{ background: green[500], padding: "5px" }} onClick={() => setopenModalAdminAllItem(index)}>
      <AssignmentIcon style={{color: '#FFFFFF'}}  />
    </IconButton>

    const _dateSend_ =  row.edit ? <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
      <KeyboardDatePicker
        variant="inline"
        margin="normal"
        format="DD/MM/YYYY"
        inputValue={moment(row.editableDateSend).format("DD/MM/YYYY")}
        onChange={(e) => handleUpdateOrder(moment(e), "editableDateSend", index)}
        autoOk={true}
      />
    </MuiPickersUtilsProvider> : moment(row.fecha_envio).format("DD/MM/YYYY")
    const _datePay_ =  row.edit ? <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
      <KeyboardDatePicker
        disableToolbar
        label="Periodo de cobro"
        variant="inline"
        margin="normal"
        id="date-picker-inline1-1"
        inputValue={moment(row.editableDatePay).format("MMMM YYYY")}
        openTo="year"
        views={["year", "month"]}
        onChange={(e) => handleUpdateOrder(moment(e), "editableDatePay", index)}
        autoOk={true}
      />

      </MuiPickersUtilsProvider>: moment(row.fecha_cobro).format("MMMM YYYY")

    const totalOrder = Object.keys(row.items).reduce((acc, key) =>
      acc + ((row.items[key]?.cantidad_envio || 0)*(medicalResourceData[row.items[key]?.id_insumo_medicamento_id]?.valor_con_iva || 0)
    ), 0);

    return [
      { value: row.numero_pedido },
      { value: moment(row.fecha_envio).format('DD/MM/YYYY'), _value_: _dateSend_},
      { value: moment(row.fecha_cobro).format('MMMM YYYY'),  _value_: _datePay_ },
      { value: toMoneyFormat(totalOrder) },
      {_value_:  <Grid item container alignItems="center" justify="center" > {_showInfo_}{_buttonAssingAllItem_}{_editButton_}{_cancelEditButton_}{_deleteButton_}</Grid>},
    ]
  })

  const selectOrderList = orderList.map((row, index) => {
    return {
      row,
      index: index,
      value: row.numero_pedido,
      label: `nº ${row.numero_pedido} - Fecha Envio ${moment(row.fecha_envio).format('DD/MM/YYYY')}`,
    }
  })

  const _adminOrderContext_ = <Grid item container xs={12}>
    <Grid item container xs={12} alignItems="center">
      <Grid item container xs={12} alignItems="center" justify="center">
        <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}> Crear Pedido</Typography>
      </Grid>
      <Grid item container md={4} lg={3} style={{padding: "0 5px"}}>
        <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
          <KeyboardDatePicker
            label="Fecha de envio"
            variant="inline"
            margin="normal"
            format="DD/MM/YYYY"
            inputValue={moment(dateSend).format("DD/MM/YYYY")}
            onChange={(e) => setdateSend(moment(e))}
            autoOk={true}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item container md={3} lg={3} style={{padding: "0 5px"}} >
        <MuiPickersUtilsProvider locale="es" utils={MomentUtils} >
          <KeyboardDatePicker
              disableToolbar
              label="Periodo de cobro"
              variant="inline"
              margin="normal"
              value={datePay}
              openTo="year"
              views={["year", "month"]}
              onChange={(e) => setdatePay(moment(e))}
              autoOk={true}
          />
      </MuiPickersUtilsProvider>
      </Grid>
      <Grid item container md={2} >
        <Button  style={{background: green[500], color: '#FFFFFF', height:"35px"}} onClick={() => handleCreateOrder()}>
          <PlaylistAddIcon style={{color: '#FFFFFF'}} /> CREAR PEDIDO
        </Button>
      </Grid>
    </Grid>
    <Grid item container xs={12} alignItems="center">
      <Grid item container xs={12} alignItems="center" justify="center">
      <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}> Pedidos en progreso</Typography>
      </Grid>
      <TableComponent
        headers={orderHeader}
        data={ordersData}
        pageSize={100}
      />
    </Grid>
  </Grid>

  const _adminOrderAction_ = <>
    {orderList.length > 0 && orderList.every((row) => row.edit !== true) ?
    <Button style={{ width: '224px', height: '42px', background: green[500], color: '#FFFFFF' }} onClick={() => setopenModalAdminOrder(false)}>
      CONTINUAR
    </Button> :
      <Button disabled style={{ width: '224px', height: '42px' }}>
      CONTINUAR
    </Button> }
  </>

  const _adminOrderWarning_ = <Grid item container xs={12} alignItems="center" justify="center">
    {openModalAdminOrderWarning !== null && Object.values(orderList[openModalAdminOrderWarning]?.items)?.length > 0 ?
      <Typography variant="body" style={{color: red[500]}}>¿Está seguro que desea eliminar el pedido? el pedido tiene insumos o medicamentos cargados</Typography> :
      <Typography variant="body" >¿Está seguro que desea eliminar el pedido?</Typography>
    }
  </Grid>

  const _adminOrderWarningAction_ = <>
    <Button style={{ width: '224px', height: '42px', background: red[500], color: '#FFFFFF' }} onClick={() => handleDeleteOrder(openModalAdminOrderWarning)}>
      ELIMINAR PEDIDO
    </Button>
    <Button style={{ width: '224px', height: '42px', background: green[500], color: '#FFFFFF' }} onClick={() => setopenModalAdminOrderWarning(null)}>
      CANCELAR
    </Button>
  </>

  const itemOrderHeader = [
    { eval: true, label: 'Item SKU' },
    { eval: true, label: 'Descripción' },
    { eval: true, label: 'Grupo producto' },
    { eval: true, label: 'Cantidad de envio' },
    { eval: true, label: 'Valor item' },
  ]

  const orderItemsData = Object.values(orderList[openModalAdminOrderInfo]?.items || {}).map((row, index) => {
    const tempItem = medicalResourceData[row.id_insumo_medicamento_id] || {};
    const quantity = row.cantidad_envio || 0;
    const price = tempItem.valor_con_iva || 0
    const total = toMoneyFormat(price*quantity)
    return [
      {value: tempItem.SKU},
      {value: tempItem.descripcion_producto},
      {value: tempItem.grupo_producto},
      {value: quantity},
      {value: total},
    ]
  })

  const _adminOrderInfoContext_ = <Grid item container xs={12}>
    <Grid item container xs={12} alignItems="center" justify="center">
      <TableComponent
        headers={itemOrderHeader}
        data={orderItemsData}
        pageSize={100}
      />
    </Grid>
  </Grid>

  const _adminOrderInfoAction_ = <>
    <Button style={{ width: '224px', height: '42px', background: green[500], color: '#FFFFFF' }} onClick={() => setopenModalAdminOrderInfo(null)}>
      CONTINUAR
    </Button>
  </>

  //----------------------------------ITEM ORDER----------------------------------------------

  const itemHeader = [
    { eval: true, label: 'nº pedido' },
    { eval: true, label: 'Fecha envio' },
    { eval: true, label: 'Fecha cobro' },
    { eval: true, label: 'Cantidad de envio' },
    { eval: true, label: 'Valor item' },
    { eval: false, label: 'Acciones',  style:{width: "180px"} },
  ]

  const handleAddItemOrder = () => {
    const tempOrderList = [...orderList];
    tempOrderList[selectOrderItem.index].items[openModalAdminItem.id] = {
      cantidad_envio: parseInt(quantitySend),
      quantitySend: parseInt(quantitySend),
      id_insumo_medicamento_id: openModalAdminItem.id_insumo_medicamento_id,
      id_prestacion_paciente_id: openModalAdminItem.id,
    }
    setselectOrderItem(ordersData[0])
    setquantitySend(1)
    setFlagCalculate(true);
  }

  const handleEditItemOrder = (row) => {
    const tempData = [...orderList];
    const indexData = tempData.findIndex((item) => item.numero_pedido === row.numero_pedido);
    const editable = !tempData[indexData].items[openModalAdminItem?.id].edit
    tempData[indexData].items[openModalAdminItem?.id].edit = editable;
    if ( !editable ) {
      tempData[indexData].items[openModalAdminItem?.id].cantidad_envio = parseInt(tempData[indexData].items[openModalAdminItem?.id].quantitySend);
      setFlagCalculate(true);
    }
    setOrderList(tempData);

  }

  const handleCancelEditItemOrder = (row) => {
    const tempData = [...orderList];
    const indexData = tempData.findIndex((item) => item.numero_pedido === row.numero_pedido);
    tempData[indexData].items[openModalAdminItem?.id].edit = false;
    tempData[indexData].items[openModalAdminItem?.id].quantitySend = tempData[indexData].items[openModalAdminItem?.id].cantidad_envio;
    setOrderList(tempData);
  }

  const handleDeletItemOrder = (row) => {
    const tempData = [...orderList];
    const indexData = tempData.findIndex((item) => item.numero_pedido === row.numero_pedido);
    tempData[indexData].items[openModalAdminItem?.id].borrar = true;
    setOrderList(tempData);
    setFlagCalculate(true);
  }

  const handleUpdateItemOrder = (value, row) => {
    const tempData = [...orderList];
    const indexData = tempData.findIndex((item) => item.numero_pedido === row.numero_pedido);
    tempData[indexData].items[openModalAdminItem?.id].quantitySend = value;
    setOrderList(tempData);
    setFlagCalculate(true);
  }

  const itemList = orderList.filter((row) => row.items[openModalAdminItem?.id]).filter((row) => row.items[openModalAdminItem?.id].borrar !== true).map((row, index) => {
    const editable = row.items[openModalAdminItem?.id].edit || false;
    const _editButton_ =<IconButton variant={"outlined"} style={{ background: editable ? indigo[500] : orange[500], padding: "5px"}} onClick={() => handleEditItemOrder(row)} >
      {editable ? <CheckIcon style={{color: '#FFFFFF'}}  /> : <CreateIcon style={{color: '#FFFFFF'}} />}
    </IconButton>
    const _cancelEditButton_ = editable ? <IconButton variant={"outlined"} style={{ background: indigo[500], padding: "5px"}} onClick={() => handleCancelEditItemOrder(row)} >
      <ClearIcon style={{color: '#FFFFFF'}} />
    </IconButton> : null
    const _deleteButton_ = <IconButton variant={"outlined"} style={{ background: red[500], padding: "5px" }} onClick={() => handleDeletItemOrder(row)} >
      <DeleteForeverIcon style={{color: '#FFFFFF'}} />
    </IconButton>

    const tempData = row.items[openModalAdminItem?.id] || {};
    const tempItem = medicalResourceData[tempData.id_insumo_medicamento_id] || {};
    const quantity = editable ? tempData.quantitySend : tempData.cantidad_envio;
    const _quantitySend_ = editable ?  <TextField
      variant="outlined"
      type="number"
      size={"small"}
      style={{ width: "100%" }}
      inputProps={{ min: "1" }}
      label={"Cantidad"}
      value={tempData.quantitySend}
      onChange={(e) => handleUpdateItemOrder(e.target.value, row)} /> : tempData.cantidad_envio
    const price = tempItem.valor_con_iva || 0
    const total = toMoneyFormat(price*quantity)

    return [
      { value: row.numero_pedido },
      { value: moment(row.fecha_envio).format("YYYY-MM-DD") },
      { value: moment(row.fecha_cobro).format("MMMM YYYY") },
      { value: quantity, _value_: _quantitySend_ },
      { value: total },
      { _value_: <Grid item container alignItems="center" justify="center" > {_editButton_}{_cancelEditButton_}{_deleteButton_}</Grid>}
    ]
  })

  const handleContinue = () => {
    const tempRepearOrder = [...repearOrder]
    const indexOrder = repearOrder.findIndex((searchRow) => openModalAdminItem.id === searchRow.id)
    openModalAdminItem.nota_prestacion = comment
    tempRepearOrder[indexOrder] = openModalAdminItem
    setRepearOrder(tempRepearOrder)
    setopenModalAdminItem(null)
  }

  let arrayInfo = [];
  if (openModalAdminItem){


    const _editNote_ =    <Grid
      container
      direction="column"
      justify="center"
      alignItems="flex-end"
      item
      xs={12}
    >
      <Grid item xs={12} style={{padding: "5px", width: "100%"}}>
        <TextField
            label={"Observaciones"}
            multiline
            rows={5}
            value={comment}
            variant="outlined"
            onChange={(e) => setComment(e.target.value)}
            style={{width: "100%"}}
            
        />
        <Typography style={{margin: "0 5px 0 0"}} variant="body2" gutterBottom>
          {`${comment?.length || 0 }/${limitObservation}`}
        </Typography>
      </Grid>
      </Grid>
    arrayInfo = [
      {label: "SKU", value: openModalAdminItem.id_insumo_medicamento__SKU},
      {label: "Detalle", value: openModalAdminItem.id_insumo_medicamento__descripcion_producto},
      {label: "Grupo producto", value: openModalAdminItem.id_insumo_medicamento__grupo_producto},
      {label: "Valor producto", value: toMoneyFormat(openModalAdminItem.id_insumo_medicamento__valor_con_iva)},
      {label: "Cantidad requerida", value: openModalAdminItem.cantidad},
      {label: "SOS", value: openModalAdminItem.es_SOS ? "Sí" : "No"},
      {_value_: _editNote_}
    ]
  }

  const _adminOrderItemContext_ = <Grid item container xs={12}>
    <Grid item container xs={12} alignItems="center">
      <Grid item container xs={12} alignItems="center" justify="center">
       <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}> Información item</Typography>
      </Grid>
      <Grid item container xs={12} alignItems="center" justify="center">
        <Grid item container xs={10} alignItems="center" justify="center">
        <Paper elevation={3} style={{margin: "10px 0", padding: "10px", width: "100%"}}>
        <Grid item container xs={12} alignItems="center">
        {arrayInfo.map((row, index) => (
          row._value_ ? 
          <Grid item container xs={12} style={{padding: "10px 0"}} alignItems="center" justify="flex-start">
            {row._value_}
          </Grid> :
          <Grid item container xs={6} alignItems="center" justify="flex-start">
            <Grid item container xs={3} alignItems="center" justify="flex-end">
              <Typography variant="body" gutterBottom style={{ textTransform: "none"}}><span>{`${row.label}:`}</span></Typography>
            </Grid>
            <Grid item container xs={1} alignItems="center" justify="center"></Grid>
            <Grid item container xs={8} alignItems="center" justify="flex-start">
              <Typography variant="body" gutterBottom style={{ textTransform: "none"}}>{row.value}</Typography>
            </Grid>
          </Grid>
        ))}
        </Grid>
        </Paper>
        </Grid>
      </Grid>
      <Grid item container xs={12} alignItems="center" justify="center">
       <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}> Ingresar item en pedido</Typography>
      </Grid>
      <Grid item container xs={12} alignItems="flex-end" justify="flex-start">
        <Grid item container md={4} lg={3} style={{padding: "0 5px"}}>
        <TextField
            variant="outlined"
            type="number"
            size={"small"}
            style={{ width: "100%" }}
            inputProps={{ min: "1" }}
            label={"Cantidad"}
            value={quantitySend} onChange={(e) => setquantitySend(e.target.value)} />
        </Grid>
        <Grid item container md={3} lg={3} style={{padding: "0 5px", margin: "0 0 -14px 0"}} >
          <DetailSelect
            value={selectOrderItem}
            validation={false}
            onchange={value => {setselectOrderItem(value)}}
            list={selectOrderList}
            label={"numero de Pedido"}
          />
        </Grid>
        <Grid item container md={2} >
          {selectOrderItem?.value ?
          <Button  style={{background: green[500], color: '#FFFFFF', height:"35px"}} onClick={() => handleAddItemOrder()}>
            <PlaylistAddIcon style={{color: '#FFFFFF'}} /> Agregar
          </Button> :
          <Button disabled  style={{ height:"35px"}}>
            <PlaylistAddIcon  /> Agregar
          </Button>
          }
        </Grid>
      </Grid>
    </Grid>
    <Grid item container xs={12} alignItems="center">
      <Grid item container xs={12} alignItems="center" justify="center">
        <Typography variant="h5" gutterBottom style={{ textTransform: "none", color: indigo[500] }}>Pedidos en progreso</Typography>
      </Grid>
      <TableComponent
        headers={itemHeader}
        data={itemList}
        pageSize={100}
      />
    </Grid>
  </Grid>

  const _adminOrderItemAction_ = <>
    {( orderList.filter((row) => row.items[openModalAdminItem?.id]).length == 0 ||
      ( orderList.filter((row) => row.items[openModalAdminItem?.id]).length > 0 &&
        orderList.filter((row) => row.items[openModalAdminItem?.id]).every((row) => row.items[openModalAdminItem?.id].edit !== true)
      )) ?
    <Button style={{ width: '224px', height: '42px', background: green[500], color: '#FFFFFF' }} onClick={() => handleContinue() }>
     CONTINUAR
   </Button>:
    <Button disabled style={{ width: '224px', height: '42px' }}>
      CONTINUAR
    </Button>
    }
  </>


//------------------Modal Admin Item------------------//

  const handleAddAllItemOrder = () => {
    const tempOrderList = [...orderList];
    const tempItems = repearOrder.filter((row) => row.cantidad).reduce((acc, obj) => {
      const tempObj = {
        cantidad_envio: parseInt(obj.cantidad),
        quantitySend: parseInt(obj.cantidad),
        id_insumo_medicamento_id: obj.id_insumo_medicamento_id,
        id_prestacion_paciente_id: obj.id,
      }
      return {
        ...acc,
        [tempObj.id_prestacion_paciente_id]: tempObj,
      }
    }, {});
    tempOrderList[openModalAdminAllItem].items = tempItems;
    setOrderList(tempOrderList);
    setopenModalAdminAllItem(null)
  }


  const _adminAllItemOrder_ =  <Grid item container xs={12}>
   <Typography variant="body" >¿Desea agregar masivamente todas las prestaciones de insumos y medicamentos al pedido?</Typography>
  </Grid>

  const _adminAllItemOrderAction_ = <>
    <Button variant="contained" color="primary" style={{ width: "224px", height: "42px", background: green[500], color: '#FFFFFF' }} onClick={handleAddAllItemOrder}>
      CARGAR PRESTACIONES
    </Button>
   <Button style={{ width: '224px', height: '42px', background: red[500], color: '#FFFFFF' }} onClick={() => setopenModalAdminAllItem(false)}>
      CANCELAR
    </Button>
  </>

  return (
    <>
    <ModalDialog
      open={true}
      onClose={() => handleClose()}
      title={"VER PEDIDO"}
      _content_={_repearOrderContext_}
      _actions_ = {_repearOrderAction_}
      maxWidth={"xl"}
    />
    <ModalDialog
      open={openModalAdminOrder}
      onClose={() => orderList.length > 0 ? setopenModalAdminOrder(false) : null}
      title={"GESTIONAR PEDIDO"}
      _content_={_adminOrderContext_}
      _actions_ = {_adminOrderAction_}
      maxWidth={"lg"}
    />
    <ModalDialog
      open={openModalAdminOrderWarning !== null}
      onClose={() => setopenModalAdminOrderWarning(null)}
      title={`ELIMINAR PEDIDO ${openModalAdminOrderWarning !== null ? orderList[openModalAdminOrderWarning].numero_pedido : ""}`}
      _content_={_adminOrderWarning_}
      _actions_ = {_adminOrderWarningAction_}
      maxWidth={"xs"}
    />
    <ModalDialog
      open={openModalAdminItem !== null}
      onClose={() => setopenModalAdminItem(null)}
      title={"GESTIONAR ITEM"}
      _content_={_adminOrderItemContext_}
      _actions_ = {_adminOrderItemAction_}
      maxWidth={"lg"}
    />
    <ModalDialog
      open={openModalAdminAllItem !== null}
      onClose={() => setopenModalAdminAllItem(null)}
      title={`ASIGNAR PEDIDO nº ${orderList[openModalAdminAllItem]?.numero_pedido}`}
      _content_={_adminAllItemOrder_}
      _actions_ = {_adminAllItemOrderAction_}
      maxWidth={"xs"}
    />
    <ModalDialog
      open={openModalAdminOrderInfo !== null}
      onClose={() => setopenModalAdminOrderInfo(null)}
      title={`VER ITEMS PEDIDO ${orderList[openModalAdminOrderInfo]?.numero_pedido}`}
      _content_={_adminOrderInfoContext_}
      _actions_ = {_adminOrderInfoAction_}
      maxWidth={"md"}
    />
    {
      modalMedical &&
      <NewModalAdd
        open={modalMedical !== null}
        table={modalMedical}
        message={false}
        nameType={titleModal}
        handleClose={() => setModalMedical(null)}
        listItem={dataTag}
        dataInfo={medicalResourceData}
        dataArray={repearOrder}
        handleAddItem={handleAddItem}
      />
    }
    </>

    );
}
