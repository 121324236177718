import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { green } from "@material-ui/core/colors";
import {
  Grid,
  Button,
  Chip,
  TextField, 
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@material-ui/core/";
import UpdateStockButton from "views/Widgets/UpdateStockButton";
import SelectExam from "../SelectExam";
import api from "utils/API";
import {
  getListSuppliesMedicines,
} from "actions/getListAction";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import MedicalTableComponent from "../../InsumosYMedicamentos/MedicalTableComponent";
import ResourceTableComponent from "../../InsumosYMedicamentos/ResourceTableComponent";
import DialogSendRequest from "../DialogSendRequest";
import apiform from "utils/APIForm";
import moment from "moment-timezone";
import {
  getListRequestPatient,
} from 'actions/getListAction';
import "moment/locale/es";
moment.locale("es");


const useStyles = makeStyles({
  microText: {
    fontSize: 10,
    color: '#BDBDBD'
  },
  answerIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: '1rem',
  },
  disabledColor: {
    color: '#BDBDBD'
  },

  '@media (max-width: 800px)': {
  },
});


const ModalRequestExam = props => {
  const dispatch = useDispatch()
  const {
    openModal,
    closeModal,
    examSelected,
    selectedListExam,
    handleExamSelected,
    handleExamDeleted,
    examsList,
    tempExam,
    setTypeRequest,
    indications,
    setIndications,
    responsable,
    setResponsable,
    dataPatient,
    dataMedicalArray,
    setDataMedicalArray,
    dataResourceArray,
    setDataResourceArray,
    clinic,
  } = props;
  const classes = useStyles();
  const [pageRefresh, setPageRefresh] = useState(false);


  const [openDialogSendRequest, setOpenDialogSendRequest] = useState(false);
  const [fileRequestExam, setFilesRequestExam] = useState(null);
  const [fileIsapreAuthorization, setFileIsapreAuthorization] = useState(null);
  const [examDate, setExamDate] = useState(moment());
  const [priority, setPriority] = useState("Media");
  const [transfer, setTransfer] = useState(true);
  const [transferType, setTransferType] = useState("Avanzado");
  const [responseFile, setresponseFile] = useState(null);

  useEffect(() => {
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const listMedical = useSelector(state => state.currentList.listMedicines);
  const listResource = useSelector(state => state.currentList.listSupplies);
  const medicalResourceData = useSelector(state => state.currentList.dictSuppliesMedicines);
  const chargeMedicine = useSelector(state => state.currentGlobalVar.chargeMedicine);

  useEffect(() => {
    if(listMedical.length === 0) {
        getListSuppliesMedicines(dispatch, chargeMedicine)
    }
  }, [])

  useEffect(() => {
    if(responseFile !== null) {
      sendExam()
    }
  }, [responseFile])

  useEffect(() => {
    if (pageRefresh) {
      setPageRefresh(false);
    }
  });

  const updateStock = () => {

    api.get("/api/poblarinsumos/").then(() => {
      getListSuppliesMedicines(dispatch, chargeMedicine, true);

    });
  };

  
  const handleResponsable = () => {
    setResponsable(!responsable);
  };

  const handlePriority = (event) => {
    setPriority(event.target.value);
  };

  const handleTransfer = () => {
    setTransfer(!transfer);
  };

  const handleTransferType = (event) => {
    setTransferType(event.target.value);
  };

  const handleExamDate = (date) => {
    setExamDate(date);
  };

  const uploadDocument = () => {
    const requestDocument = []
    if (fileRequestExam != null) {
      const formData = new FormData();
      formData.append("archivo", fileRequestExam[0]);
      formData.append("tipo_documento", "OrdenExamenes");
      formData.append("nombre_archivo", fileRequestExam[0].name);
      formData.append("id_paciente", dataPatient.id);
      const requestOrder = apiform.post(`/api/documentospaciente/`,formData)
      requestDocument.push(requestOrder)

    }
    if (fileIsapreAuthorization !== null) {
      const formData2 = new FormData();
      formData2.append("archivo", fileIsapreAuthorization[0]);
      formData2.append("tipo_documento", "AutorizacionExamenesIsapre");
      formData2.append("nombre_archivo", fileIsapreAuthorization[0].name);
      formData2.append("id_paciente", dataPatient.id);

      const requestIsapre = apiform.post(`/api/documentospaciente/`,formData2)
      requestDocument.push(requestIsapre)
    }
    if(requestDocument.length > 0){
      Promise.all(requestDocument).then((request) => {
        const listTempResponseFile = request.map((response) => response.data)
        setresponseFile(listTempResponseFile)
      })
    } else {
      setresponseFile([])
    }
    
  }

  const sendExam = () => {
    let tempListaInsumos = [];
    dataMedicalArray.map((row) => {
      tempListaInsumos.push({
        cantidad: row.cantidad,
        id_insumo_medicamento: row.id,
      });
    });
    dataResourceArray.map((row) => {
      tempListaInsumos.push({
        cantidad: row.cantidad,
        id_insumo_medicamento: row.id,
      });
    });
    let sendPost = {
      indicaciones: indications,
      realiza_cmh: responsable,
      fecha_hora_examen: moment(examDate).format("YYYY-MM-DDTHH:mm:ss"),
      traslado: transfer,
      prioridad: priority,
      lista_examen_clinica: selectedListExam.map((row) => row.value),
      lista_insumos: tempListaInsumos,
    };
    if (transfer) {
      sendPost["tipo_traslado"] = transferType;
    }

    responseFile.map((file) => {
      if(file.tipo_documento === "OrdenExamenes"){
        sendPost["id_orden_examen"] = file.id
     
      } else if(file.tipo_documento === "AutorizacionExamenesIsapre"){
        sendPost["id_autorizacion_isapre"] = file.id
      }
    })

    api.post(`/api/paciente/${dataPatient.id}/solicitudes/examenes/`, sendPost).then((response) => { 
        getListRequestPatient(dataPatient, dispatch)
        setOpenDialogSendRequest(true);
        setresponseFile(null)
      })
  };


  const valuesSelected = selectedListExam.map((row) => row.value)
  const auxExamList = examsList.filter(exam => !valuesSelected.includes(exam.value));

  const _content_ =  <div>
    <Grid item container xs={12} justify="center">
      <SelectExam
        examSelected={examSelected}
        handleExamSelected={handleExamSelected}
        examsList={auxExamList}
      />
    </Grid>
    <Grid item container xs={12} justify="center">
        {selectedListExam.map(examSelected => {
            return (
              <Chip
                key={examSelected.value}
                className='mr-2 mb-2'
                label={examSelected.label}
                onDelete={() => handleExamDeleted(examSelected.value)}
                variant="outlined"
                color='primary'
              />
            )
          })}
    </Grid>
    {tempExam != "" ? (
        <Grid item container xs={12} justify="center">
        {pageRefresh && <div></div>}
        <Grid item container xs={12} justify="center" alignItems="center">
          <Grid item xs={1} />
          <Grid item container xs={10} justify="center" alignItems="center">
            <Grid
              item
              container
              xs={12}
              justify="center"
              alignItems="center"
              //spacing={1}
            >
              <Grid item container xs={4} justify="center">
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend">
                    ¿Quién realiza el análisis?
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={responsable}
                    onChange={handleResponsable}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio color="primary" />}
                      label="CMH"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio color="primary" />}
                      label="Laboratorio Externo"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item container xs={4} justify="center" alignItems="center">
                <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                  <Grid container justify="center">
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD-MM-YYYY"
                      margin="normal"
                      id="date-picker-inline1"
                      label="Fecha del examen"
                      value={examDate}
                      onChange={handleExamDate}
                      minDate={moment()}
                      minDateMessage={"La fecha del examen no puede ser anterior al día de hoy"}
                      cancelLabel={"Cancelar"}
                      okLabel={"Aceptar"}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
                  <Grid container justify="center">
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Hora del examen"
                      value={examDate}
                      onChange={handleExamDate}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      cancelLabel={"Cancelar"}
                      okLabel={"Aceptar"}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item container xs={4} justify="center">
                <Grid item container xs={12} justify="center">
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    style={{ marginBottom: "4px" }}
                  >
                    <span>
                      {fileRequestExam === null ? "" : fileRequestExam[0].name}{" "}
  
                    </span>
                  </Grid>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Adjuntar orden de examen
                    <TextField
                      type="file"
                      id="exam"
                      onChange={(event) =>
                        setFilesRequestExam(event.target.files)
                      }
                      fullWidth
                      my={2}
                      style={{ display: "none" }}
                      accept={("pdf", "jpg", "jpeg", "png")}
                    />
                  </Button>
                </Grid>
                <Grid item container xs={12} justify="center">
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    style={{ marginBottom: "4px" }}
                  >
                    <span>
                      {fileIsapreAuthorization
                        ? fileIsapreAuthorization[0].name
                        : ""}
  
                    </span>
                  </Grid>
                  {!clinic &&
                    <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ textTransform: "none", marginRight: "3px" }}
                  >
                    Adjuntar autorización de isapre
                    <TextField
                      type="file"
                      id="exam"
                      onChange={(event) =>
                        setFileIsapreAuthorization(event.target.files)
                      }
                      fullWidth
                      my={2}
                      style={{ display: "none" }}
                      accept={("pdf", "jpg", "jpeg", "png")}
                    />
                  </Button>
                  }
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
  
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginTop: "2em" }}
        >
          <Grid item xs={1} />
          <Grid item container xs={10} justify="center">
            {transfer ? (
              <Grid item container xs={12} justify="center" spacing={1}>
                <Grid item container xs={4} justify="center">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Prioridad
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={priority}
                      onChange={handlePriority}
                      label="Prioridad"
                    >
                      <MenuItem value={"Baja"}>Baja</MenuItem>
                      <MenuItem value={"Media"}>Media</MenuItem>
                      <MenuItem value={"Alta"}>Alta</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container xs={4} justify="center">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label-1">
                      Traslado
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label-1"
                      id="demo-simple-select-outlined-1"
                      value={transfer}
                      onChange={handleTransfer}
                      label="Traslado"
                    >
                      <MenuItem value={true}>Si</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container xs={4} justify="center">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label-2">
                      Tipo de traslado
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label-2"
                      id="demo-simple-select-outlined-2"
                      value={transferType}
                      onChange={handleTransferType}
                      label="Tipo de traslado"
                    >
                      <MenuItem value={"Auto"}>Auto</MenuItem>
                      <MenuItem value={"Basico"}>Básico</MenuItem>
                      <MenuItem value={"Medio"}>Medio</MenuItem>
                      <MenuItem value={"Avanzado"}>Avanzado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Grid item container xs={12} justify="center" spacing={1}>
                <Grid item container xs={6} justify="center">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Prioridad
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={priority}
                      onChange={handlePriority}
                      label="Prioridad"
                    >
                      <MenuItem value={"Baja"}>Baja</MenuItem>
                      <MenuItem value={"Media"}>Media</MenuItem>
                      <MenuItem value={"Alta"}>Alta</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item container xs={6} justify="center">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Traslado
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={transfer}
                      onChange={handleTransfer}
                      label="Prioridad"
                    >
                      <MenuItem value={true}>Si</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={1} />
        </Grid>
  
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginTop: "2em" }}
        >
          <Grid item xs={1} />
          <Grid item container xs={10} justify="center">
            <Grid item container xs={12} justify="center">
              <TextField
                fullWidth
                multiline
                rows={4}
                id="outlined-basic2"
                label="Indicaciones"
                variant="outlined"
                value={indications}
                onChange={(e) => setIndications(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={1} />
        </Grid>
  
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginTop: "2em" }}
        >
          <Grid item container xs={1} />
          <Grid item container xs={10} justify="center">
            <MedicalTableComponent
              dataMedicalArray={dataMedicalArray}
              setDataMedicalArray={setDataMedicalArray}
              medicalResourceData={medicalResourceData}
              //listMedical={listMedical}
              listMedical={listMedical.filter((list) =>
                dataMedicalArray.every((data) => data.id !== list.value)
              )}
              setPageRefresh={setPageRefresh}
            />
          </Grid>
          <Grid item container xs={1} />
        </Grid>
  
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginTop: "2em" }}
        >
          <Grid item xs={1} />
          <Grid item container xs={10} justify="center">
            <ResourceTableComponent
              dataResourceArray={dataResourceArray}
              setDataResourceArray={setDataResourceArray}
              medicalResourceData={medicalResourceData}
              //listResource={listResource}
              listResource={listResource.filter((list) =>
                dataResourceArray.every((data) => data.id !== list.value)
              )}
              setPageRefresh={setPageRefresh}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
        {openDialogSendRequest && (
          <DialogSendRequest
            openDialog={openDialogSendRequest}
            closeDialog={() => setOpenDialogSendRequest(false)}
            setTypeRequest={setTypeRequest}
          />
        )}
      </Grid>
    ) : null}
   <UpdateStockButton
      updateStock={updateStock}
    />
</div>

  const _actions_ = <>
      {selectedListExam.length > 0 ?
        <Button
          variant="contained"
          className="text-white"
          style={{
            marginLeft: "1em",
            textTransform: "none",
            backgroundColor: green[500],
          }}
          onClick={() => {uploadDocument()}}
        >
          Enviar
        </Button> :
        <Button
          disabled
          variant="contained"
          className="text-white"
          style={{
            marginLeft: "1em",
            textTransform: "none",
          }}
       >
         Enviar
       </Button>
      }
      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: "none",
        }}
        onClick={closeModal}
      >
        Salir
      </Button>
  </>

  return (
    <ModalDialog
        open={openModal}
        onClose={closeModal}
        title={`Ingresar datos de los examenes a crear`}
        _content_={_content_}
        _actions_ = {_actions_}
    />
  );
};

export default ModalRequestExam;

